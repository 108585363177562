import React, { useRef, useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom'
import { GetCategoriesList } from '../APIS/apis'
import SlickSlider from '../SlickSlider/SlickSlider'
import { ThreeCircles } from 'react-loader-spinner'
import './Categories.css'


function Categories({ categoryResults, userCountry, selectedCategory, selectedWatchProvider, seturl, setLoadMoreArray, setPages, setPagesNo }) {
    let [listOfCategory, setListOfCategory] = useState([])
    const [listOfName, setListOfName] = useState([])
    const [loader, setLoader] = useState(true)

    // const location = useLocation()
    const listOfCategories = useRef([])
    const listOfWatchProvider = useRef([])
    let Moviecategory = []
    let TvCategory = []
    let category = []

    const addCategoryId = () => {
        listOfCategories.current = selectedCategory
    }

    const addWatchProvider = () => {
        listOfWatchProvider.current = selectedWatchProvider
    }

    useEffect(() => {
        if (categoryResults.current.length === 0) {
            addCategoryId()
            addWatchProvider()
            // addCategoryName()
            setListOfCategory([])
            setLoader(true)
            if (listOfWatchProvider.current.length > 1 && listOfCategories.current.length == 1) {
                listOfWatchProvider.current.map(async (x) => {
                    await GetCategoriesList(listOfCategories.current[0].genre, x.name, x.id, listOfCategories.current[0].type, userCountry).then(res => {
                        if (res[0].type === 'movie') {
                            Moviecategory.push(res)
                        } else {
                            TvCategory.push(res)
                        }
                        Moviecategory = Moviecategory.sort(Comparator)
                        TvCategory = TvCategory.sort(Comparator)
                        category = Moviecategory.concat(TvCategory)
                    })
                    setTimeout(() => {
                        setLoader(false)
                        let known = new Set()
                        let filtered = category.map(subarray =>
                            subarray.filter(item => !known.has(item.poster_path) && known.add(item.poster_path))
                        )
                        categoryResults.current = filtered
                    }, 1000);
                })
            }
            else if (listOfWatchProvider.current.length >= 1 && listOfCategories.current.length == 0) {

                const homeSliders = [
                    {
                        id: 35,
                        type: 'movie'
                    },
                    {
                        id: 16,
                        type: 'movie'
                    },
                    {
                        id: 99,
                        type: 'tv'
                    },
                    {
                        id: 35,
                        type: 'tv'
                    }
                ]
                
                listOfWatchProvider.current.forEach((x) => {
                    homeSliders.map(async (y) => {
                        GetCategoriesList(y.id, x.name, x.id, y.type, userCountry).then(res => {
                                if(res.length>0) {
                                if (res[0].type === 'movie') {
                                    Moviecategory.push(res)
                                } else {
                                    TvCategory.push(res)
                                }
                                Moviecategory = Moviecategory.sort(Comparator)
                                TvCategory = TvCategory.sort(Comparator)
                                category = Moviecategory.concat(TvCategory)
                            }
                        })

                        setTimeout(() => {
                            setLoader(false)
                            let known = new Set()
                            let filtered = category.map(subarray =>
                                subarray.filter(item => !known.has(item.poster_path) && known.add(item.poster_path))
                            )
                            categoryResults.current = filtered
                        }, 1000);
                    })
                })
            }
            else if (listOfWatchProvider.current.length >= 1 && listOfCategories.current.length >= 1) {
                listOfCategories.current.map((x) => {
                    listOfWatchProvider.current.map(async (y) => {                        
                        await GetCategoriesList(x.genre, y.name, y.id, x.type, userCountry).then(res => {
                            if (res[0].type === 'movie') {
                                Moviecategory.push(res)
                            } else {
                                TvCategory.push(res)
                            }
                            Moviecategory = Moviecategory.sort(Comparator)
                            TvCategory = TvCategory.sort(Comparator)
                            category = Moviecategory.concat(TvCategory)
                        })
                        setTimeout(() => {
                            setLoader(false)
                            let known = new Set()
                            let filtered = category.map(subarray =>
                                subarray.filter(item => !known.has(item.poster_path) && known.add(item.poster_path))
                            )
                            categoryResults.current = filtered
                        }, 1000);
                    })
                })
            } else if (listOfWatchProvider.current.length == 0 && listOfCategories.current.length >= 1) {
                listOfCategories.current.map(async (x) => {                    
                    await GetCategoriesList(x.genre, listOfWatchProvider.current.name, listOfWatchProvider.current.id, x.type, userCountry).then(res => {                    
                        if (res[0].type === 'movie') {
                            Moviecategory.push(res)
                        } else {
                            TvCategory.push(res)
                        }
                        Moviecategory = Moviecategory.sort(Comparator)
                        TvCategory = TvCategory.sort(Comparator)
                        category = Moviecategory.concat(TvCategory)
                    })
                    setTimeout(() => {
                        setLoader(false)
                        let known = new Set()
                        let filtered = category.map(subarray =>
                            subarray.filter(item => !known.has(item.poster_path) && known.add(item.poster_path))
                        )

                        categoryResults.current = filtered
                    }, 1000);
                })
            }
        } else {
            setListOfCategory(categoryResults.current)
        }
    }, [selectedCategory, selectedWatchProvider])

    useEffect(() => {
        if (listOfCategory.length > 0) {
            setLoader(false)
        }
    }, [listOfCategory])

    function Comparator(a, b) {
        if (a[1]?.category_name < b[1]?.category_name) return -1;
        if (a[1]?.category_name > b[1]?.category_name) return 1;
        return 0;
    }

    useEffect(() => {
        setListOfCategory(categoryResults.current)
    }, [categoryResults.current])

    useEffect(() => {        
        setListOfCategory(categoryResults.current)
    }, [])



    return (
        <div className='filter_results'>            
            <SlickSlider list={listOfCategory} heading={listOfName} seturl={seturl} setLoadMoreArray={setLoadMoreArray} setPages={setPages} setPagesNo={setPagesNo} />
            {listOfCategory.length == 0 &&
            <h2 className='text-white pt-4 mb-3'>No results founded on search</h2>}
            <ThreeCircles
                height="100"
                width="100"
                color="#ffe01b"
                wrapperStyle={{ margin: 'auto' }}
                wrapperClass="loader"
                visible={loader}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </div>
    )
}


export default Categories