import React, { useEffect, useState, useRef } from 'react'
import './details.css';
import { useLocation, useParams } from 'react-router-dom'
import { GetDetailsFromStreamDip, GetCast, Similar, getWishList, addWishListApi, deleteWishList, Trailer } from '../APIS/apis'
import moment from 'moment';
import { ThreeCircles } from 'react-loader-spinner'
import { BsFillPlayFill } from 'react-icons/bs';
import Seasons from './Seasons';
import SimilarSlider from './components/SimilarSlider';
import WatchProviders from './components/WatchProviders';
import WatchProvidersDesktop from './components/WatchProvidersDesktop';
import CastSlider from './components/CastSlider';


function Details({ userDetails, userCountry }) {
    // console.log(userCountry)
    const backDropPath = 'https://image.tmdb.org/t/p/w500'    
    const params = useParams()
    const location = useLocation()
    // const navigate = useNavigate();    
    const [details, setDetails] = useState({})
    const [type] = useState(location.pathname.includes('find-series/') ? 'tv' : 'movie')
    const [castMember, setCastMember] = useState([])
    const [similarMoviesTvShows, setSimilarMoviesTvShows] = useState([])
    const [wishListArray, setWishListArray] = useState(false)
    const [trailerId, setTrailerId] = useState('')
    const [trailerActive, setTrailerActive] = useState(false)
    const [selectedSeason, setSelectedSeason] = useState(null)
    const [loader, setLoader] = useState(true)

    const trailerModal = useRef()

    const addToWishList = async (id, poster_path, type, title) => {
        setLoader(true)
        if (userDetails) {
            await addWishListApi(id, poster_path, type, userDetails.email, title)
            setWishListArray(true)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    const deleteFromWishList = async (id, type) => {
        setLoader(true)
        await deleteWishList(id, type, userDetails.email)
        setWishListArray(false)
        setLoader(false)
    }

    const similarFunc = async (id, type) => {
        let detail = await GetDetailsFromStreamDip(id, type, params.title, null, userCountry)
        const cast = await GetCast(id, type)
        const similar = await Similar(id, type)
        setCastMember(cast)
        // detail = DetailResultHandler(detail, userCountry)        
        setDetails(detail)
        setSimilarMoviesTvShows(similar)
    } 

    useEffect(() => {
        (async () => {            
            if (userDetails && details) {
                const wishListArray = await getWishList(userDetails.email)
                for (var i = 0; i < wishListArray.length; i++) {
                    if (wishListArray[i].id === details.id) {
                        setWishListArray(true)
                        break;
                    } else {
                        setWishListArray(false)
                    }
                }
            }
        })
            ();
        window.scrollTo(0, 0)
    }, [userDetails, details])

    useEffect(() => {
        //console.log(`User countryyyyyy: ${userCountry}`)
        (async () => {    
            //console.log(`Passei aqui`)  
            setLoader(true)      
            let type = 'movie'
            if (location.pathname.includes('find-series/')) {
                type = 'tv'
            }
            let detail = ''
            if (params.id === undefined && type === undefined && params.title === undefined) {                
                detail = await GetDetailsFromStreamDip(params.id, type, params.title, null, userCountry)
            } else {
                detail = await GetDetailsFromStreamDip(params.id, type, params.title, null, userCountry)
            }

            //console.log(detail)
            //detail = DetailResultHandler(detail, userCountry)
            
            setDetails(detail)
            const cast = await GetCast(params.id, type)
            const similar = await Similar(params.id, type)
            setCastMember(cast)
            setSimilarMoviesTvShows(similar)
            setLoader(false)
            
        })
            ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCountry, params])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!trailerModal?.current?.contains(event.target)) {
                setTrailerActive(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trailerModal]);

    useEffect(() => {
        (async () => {
            if (details && details?.id && details?.type) {
                document.title = `StreamDip | ${details.title || ''} | ${details.overview || ''}`;
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', details.overview);
                }
                const trailers = await Trailer(details?.id, details?.type)
                setTrailerId(trailers?.key)                
            }
        })
            ();
    }, [details])

    useEffect(() => {
        //console.log(`${selectedSeason} was selected`)
    }, [selectedSeason])

    return (
        <>
            {!loader &&
                <>
                    <section className="detail_section movie_series_detail">
                        <div className="container-fluid">
                            <div>
                                <div className="background_path_home" style={{ backgroundImage: `url('${backDropPath}${details.backdrop_path}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                    <div className="row pt-5 main_detail_row" style={{ backgroundImage: 'linear-gradient(to right, rgba(22, 22, 26, 1) 150px, rgba(22, 22, 26, 0.84) 100%)' }}>
                                        <div className='col-md-12 detail_upper_btn_section'>                                            
                                            {wishListArray ?
                                                <button className='btn btn_wishlist float-end bg-white text-black position-relative' onClick={() => deleteFromWishList(details.id, details.type)}>
                                                    {!loader ? 'Remove from WatchList' :
                                                        <ThreeCircles
                                                            height="100"
                                                            width="100"
                                                            color="#ffe01b"
                                                            wrapperStyle={{ margin: 'auto' }}
                                                            wrapperClass="loader"
                                                            visible={loader}
                                                            ariaLabel="three-circles-rotating"
                                                            outerCircleColor=""
                                                            innerCircleColor=""
                                                            middleCircleColor=""
                                                        />}
                                                </button>
                                                :
                                                <>
                                                    {userDetails ?
                                                        <button className='btn btn_wishlist btn_wishlist_add float-end position-relative' onClick={() => addToWishList(details.id, details.poster_path, details.type, details.title)} >
                                                            {!loader ? 'Add to WatchList' :
                                                                <ThreeCircles
                                                                    height="100"
                                                                    width="100"
                                                                    color="#ffe01b"
                                                                    wrapperStyle={{ margin: 'auto' }}
                                                                    wrapperClass="loader"
                                                                    visible={loader}
                                                                    ariaLabel="three-circles-rotating"
                                                                    outerCircleColor=""
                                                                    innerCircleColor=""
                                                                    middleCircleColor=""
                                                                />
                                                            }
                                                        </button>
                                                        :
                                                        <button className='btn btn_wishlist btn_wishlist_add float-end' data-bs-toggle="modal" data-bs-target="#signin" >Add to WatchList</button>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-8 col-md-12 mx-auto">
                                            <div className="row">
                                                <div className="col-md-4 col-5">
                                                    <div className="detail_poster">
                                                        <img src={`${backDropPath}${details.poster_path}`} width="100%" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-7 movie_detail_padding">
                                                    <div className="movie_details">
                                                        <div className='row'>
                                                            <div className='col-lg-8 col-md-12'>
                                                                <h2 className="title mb-2 text-white">{details.original_title}</h2>
                                                                <p className="release_date mb-0">{moment(details.release_date).format('MMMM Do YYYY')}{details.runtime && <> <span className="mx-1">.</span> <>{details.runtime} Minutes</></>}</p>
                                                                <p className="genre">
                                                                    {details.genres?.map((x, i) => (
                                                                        <small key={i}>
                                                                            {x.name}<span className="mx-1">.</span>
                                                                        </small>
                                                                    ))}
                                                                </p>
                                                                {trailerId &&
                                                                    <span className='play_trailer' aria-hidden="true" data-bs-toggle="modal" data-bs-target="#trailerModal" onClick={() => setTrailerActive(true)}><BsFillPlayFill style={{ fontSize: '30px', marginLeft: '-7px', marginTop: '-3px' }} /> Play Trailer</span>
                                                                }
                                                                <WatchProviders details={details} />
                                                            </div>
                                                        </div>
                                                        <div className='movie_deatils movie_details_desktop'>
                                                            <h5 className="overview_label mt-4 text-white">Overview
                                                                <span className='mx-3 rating-icon'> {details?.imdb?.length > 0 ? <span><img src="/imdb.png" width='30px' alt="" /> {details.imdb[0]?.Value}</span> : ''} </span> <span className='rating-icon'> {details?.tomatometer?.length > 0 ? <span><img src="/rt.png" width='25px' alt="" /> {details.tomatometer[0]?.Value} </span> : ''} </span>
                                                            </h5>
                                                            <p className="overview">{details.overview}</p>
                                                            <WatchProvidersDesktop details={details} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className='col-md-12'>
                        <div className='movie_deatils movie_details_mob movie_details_mobile_two'>
                            <h5 className="overview_label mt-4 text-white">Overview
                                <span className='mx-3 rating-icon'>{details?.imdb?.length > 0 ? <span><img src="/imdb.png" width='30px' alt="" /> {details.imdb[0]?.Value}</span> : ''} </span> <span className='rating-icon'> {details?.tomatometer?.length > 0 ? <span><img src="/rt.png" width='25px' alt="" /> {details.tomatometer[0]?.Value}</span> : ''} </span>
                            </h5>
                            <p className="overview">{details.overview}</p>
                        </div>
                    </div>                    
                    {details.seasons && <Seasons list={details.seasons} id={params.id} type={type} name={details.name} title={params.title} setSelectedSeason={setSelectedSeason}/>}
                    
                    {castMember.length > 0 && <CastSlider list={castMember} />}

                    {similarMoviesTvShows.length > 0 && <SimilarSlider list={similarMoviesTvShows} similarFunc={{similarFunc}}/>}
                    
                    <div className="modal fade" id="trailerModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog bg-black">
                            <div className="modal-content position-relative h-100">
                                <div className="modal-header bg-black border-0 ">
                                    <h5 className="modal-title text-white" id="staticBackdropLabel">Play Trailer</h5>
                                    <button type="button" className='btn' data-bs-dismiss="modal" aria-label="Close" onClick={() => setTrailerActive(false)}><i style={{ fontSize: "24px" }} className="fa fa-times text-white" aria-hidden="true"></i></button>
                                </div>
                                {trailerActive ?
                                    <iframe
                                        src={`https://www.youtube.com/embed/${trailerId}?rel=0&amp;autoplay=1`}
                                        frameborder='0'
                                        allow="autoplay"
                                        allowfullscreen
                                        title='video'
                                        className='responsive-iframe'
                                    />
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

            <ThreeCircles
                height="100"
                width="100"
                color="#ffe01b"
                wrapperStyle={{ margin: 'auto' }}
                wrapperClass="loader"
                visible={loader}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </>
    )
}

export default Details