
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import { CiFilter } from 'react-icons/ci';
import { AiOutlineSearch } from 'react-icons/ai';
import { AiFillCloseCircle } from 'react-icons/ai';

export default function Filters({
    movieGenreFilter,
    setMovieGenreFilter,
    seriesGenreFilter,
    setSeriesGenreFilter,
    streamingFilter,
    setStreamingFilter,
    setSideBarActive,
    movieCategories,
    seriesCategories,
    genreFilterSelected,
    watchProviderSelected,
    watchProviders,
    watchProviderSearch,
    watchProviderSearchClose,
    setWatchProviderSearchQuery,
    watchProviderSearchQuery,
    selectAllStreaming,
    streamingFilterSelected,
    categorySelected,
    setSelectedCategory,
    setSelectedWatchProvider
}) {
    const location = useLocation();

    return <div className={`lower_nav ${location.pathname !== "/" ? 'd-none' : ''}`}>
                <h2 className='text-black pt-4 mb-3'>Search Netflix, Apple TV+, Disney+ and more in one place!</h2>
                <button className={`btn filter_btn ${movieGenreFilter.length > 0 || seriesGenreFilter.length > 0 || streamingFilter.length > 0 ? "active_filter" : ''}`} onClick={() => { setSideBarActive(true); }}><CiFilter style={{ fontSize: '20px' }} />Search</button>
                <ul className='nav_links'>
                    <li className={`nav-item dropdown ${movieGenreFilter.some(item => item.type === 'movie') ? 'active' : ''}`}>
                        <a className="nav-link dropdown-toggle" href="#" id="three" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            Movies
                        </a>
                        <ul className="dropdown-menu overflow-hidden" aria-labelledby="three">
                            <li>
                                <ul className='inner_list mt-3 mx-3'>                                    
                                    {movieCategories && movieCategories.map((x, i) => (
                                        <li key={i}>
                                            <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                <input className="form-check-input" type="checkbox" value={x.id} id={`flexCheckDefault-${i}`} onChange={() => genreFilterSelected(x.id, 'movie')} />
                                                <label className={`form-check-label ${movieGenreFilter.some(item => item.genre === x.id) ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                    {x.name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                    <li key={movieCategories?.length + 1}>
                                        <div className="form-check me-2 ps-0 mb-1 position-relative">
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={() => genreFilterSelected('trending', 'movie')} />
                                            <label className={`form-check-label ${movieGenreFilter.some(item => item.genre === 'trending') ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                Trending
                                            </label>
                                        </div>
                                    </li>                                    
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className={`nav-item dropdown ${seriesGenreFilter.some(item => item.type === 'tv') ? 'active' : ''}`}>
                        <a className="nav-link dropdown-toggle" href="#" id="four" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            Series
                        </a>
                        <ul className="dropdown-menu overflow-hidden" aria-labelledby="four">
                            <li>
                                <ul className='inner_list mt-3 mx-3'>
                                    <>
                                        {seriesCategories && seriesCategories.map((x, i) => (
                                            <li key={i}>
                                                <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                    <input className="form-check-input" type="checkbox" value={x.id} id={`flexCheckDefault-${i}`} onChange={() => genreFilterSelected(x.id, 'tv')} />
                                                    <label className={`form-check-label ${seriesGenreFilter.some(item => item.genre === x.id) ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                        {x.name}
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                        <li key={seriesCategories?.length + 1}>
                                            <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={() => genreFilterSelected('trending', 'tv')} />
                                                <label className={`form-check-label ${seriesGenreFilter.some(item => item.genre === 'trending') ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                    Trending
                                                </label>
                                            </div>
                                        </li>
                                    </>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className={`nav-item dropdown ${streamingFilter.length > 0 ? 'active' : ''}`}>
                        <a className="nav-link dropdown-toggle" href="#" id="five" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            Streaming Services
                        </a>

                        <ul className="dropdown-menu py-3" aria-labelledby="five">
                            <li className='watchprovider_search_wrapper'>
                                <AiOutlineSearch className='watchprovider_search' id='watchProviderSearch' style={{ fontSize: '20px' }} onClick={() => watchProviderSearch()} />
                                <AiFillCloseCircle className='watchprovider_search_close' id='watchProviderSearchClose' style={{ fontSize: '20px' }} onClick={() => { watchProviderSearchClose(); setWatchProviderSearchQuery('') }} />
                                {/* <i className="fa fa-times watchprovider_search_close" id='watchProviderSearchClose' aria-hidden="true" onClick={() => { watchProviderSearchClose(); setWatchProviderSearchQuery('') }}></i> */}
                                {/* <i className="fa fa-search watchprovider_search" id="watchProviderSearch" aria-hidden="true" onClick={() => watchProviderSearch()}></i> */}
                                <div className="input-group mb-3 watchprovider_search_field" id='watchProviderSearchField'>
                                    <input type="text" className="form-control" id='watchProviderSearchInput' value={watchProviderSearchQuery} placeholder="Search ..." aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setWatchProviderSearchQuery(e.target.value)} />
                                    <span className="input-group-text" id="basic-addon1">
                                        {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                                        <AiOutlineSearch style={{ fontSize: '20px' }} />
                                    </span>
                                </div>
                            </li>
                            <li>
                                <ul className='inner_list mt-3 mx-3'>
                                    {watchProviders.length === 0 && <p className='text-black'> No Watch Providers are available in your region.</p>}
                                    <>
                                        {watchProviderSearchQuery === '' && watchProviders.length > 0 ?
                                            <li>
                                                <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={() => selectAllStreaming()} />
                                                    <label className={`form-check-label ${streamingFilter.length === watchProviders.length ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                        All
                                                    </label>
                                                </div>
                                            </li>
                                            :
                                            ""
                                        }
                                        {watchProviders && watchProviders.filter((x) => {
                                            if (watchProviderSearchQuery == '') {
                                                return x
                                            } else if (x.provider_name.toLowerCase().includes(watchProviderSearchQuery.toLowerCase())) {
                                                return x
                                            }
                                        }).map((x, i) => (                                            
                                                x.provider_name !== 'Apple TV' && x.provider_name !== 'HBO Max' &&
                                                    <li key={i}>
                                                        <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                            <input className="form-check-input" type="checkbox" value={x.provider_id} id={`flexCheckDefault-${i}`} onChange={() => streamingFilterSelected(x.provider_name, x.provider_id)} />
                                                            <label className={`form-check-label ${streamingFilter.some(item => item.id === x.provider_id) ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                                {x.provider_name}
                                                            </label>
                                                        </div>
                                                    </li>                                                                                                
                                        ))}
                                    </>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className={`${movieGenreFilter.length > 0 || seriesGenreFilter.length > 0 || streamingFilter.length > 0 ? 'btn_apply_filter' : 'btn_clear_all'} position-relative`} data-tooltip-id="my-tooltip" data-tooltip-content="Select at least one option" onClick={() => { categorySelected(movieGenreFilter, seriesGenreFilter); watchProviderSelected(streamingFilter) }} style={{ cursor: movieGenreFilter.length > 0 || seriesGenreFilter.length > 0 || streamingFilter.length > 0 ? 'pointer' : 'no-drop' }}>
                        Search
                        {movieGenreFilter.length > 0 || seriesGenreFilter.length > 0 || streamingFilter.length > 0 ? '' : <Tooltip id="my-tooltip" />}
                    </li>

                    <li className='btn_clear_all' onClick={() => { setSelectedCategory([]); setSelectedWatchProvider([]); setMovieGenreFilter([]); setSeriesGenreFilter([]); setStreamingFilter([]) }}>
                        Clear All
                    </li>
                </ul>
            </div>
}