import axios from "axios";
import { getCookie, setCookie } from "../Services/TokenFunction";
const API_KEY = 'ab69a8dae367fa71b35c1ebcc00bb16a';
const BASE_URL = 'https://api.themoviedb.org/';
const SD_API = process.env.REACT_APP_API_URL || '';
const headers = { accept: "application/json" };

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

const getAuthHeader = async (headers) => {
    let token = getCookie('token')
    
    if(token) {

        const decodedJwt = parseJwt(token);

        if (decodedJwt.exp * 1000 < Date.now()) {
            const refreshToken = getCookie('refreshToken')
            let newTokens = await renewToken(refreshToken)
            console.log(`Expiradoooo vou usar o refresh`)
            
            setCookie('token', newTokens.accessToken, 7)
            token = newTokens.accessToken
            
        } else {
            console.log('Não expirou')
        }

        headers['Authorization'] = `Bearer ${token}`
    }
    return headers
}

export const GetCategoriesMovie = async () => {
    let { data: { genres } } = await axios.get(`${BASE_URL}3/genre/movie/list?api_key=${API_KEY}&language=en-US`, { headers })
    return genres
};

export const GetCategoriesMovieAsync = () => {
    return axios.get(`${BASE_URL}3/genre/movie/list?api_key=${API_KEY}&language=en-US`, { headers })    
};

export const GetCategoriesTv = async () => {
    let { data: { genres } } = await axios.get(`${BASE_URL}3/genre/tv/list?api_key=${API_KEY}&language=en-US`, { headers })
    return genres
};

export const GetCategoriesTvAsync = () => {
    return axios.get(`${BASE_URL}3/genre/tv/list?api_key=${API_KEY}&language=en-US`, { headers })    
};

export const GetWatchProviders = async (country) => {
    let { data: { results } } = await axios.get(`${BASE_URL}3/watch/providers/movie?api_key=${API_KEY}&language=en-US&watch_region=${country}`, { headers })
    return results
};

export const GetWatchProvidersAsync = (country) => {
    return axios.get(`${BASE_URL}3/watch/providers/movie?api_key=${API_KEY}&language=en-US&watch_region=${country}`, { headers })
};

export const GetList = async (url, heading) => {
    if (window.location.pathname !== "/trending" && window.location.pathname !== "/") {
        let a = []
        for (let i = 1; i <= 2; i++) {
            var str2 = url.slice(0, -1) + i;
            let { data: { results } } = await axios.get(`${str2}`, { headers })
            a = [...a, results]
        }
        a = a.map((x) => x.map((y) => {
            if (y.first_air_date) {
                return {
                    poster_path: y.poster_path,
                    id: y['id'],
                    type: 'tv',
                    category_name: heading,
                    url: url
                };
            } else {
                return {
                    poster_path: y.poster_path,
                    id: y['id'],
                    type: 'movie',
                    category_name: heading,
                    url: url
                };
            }
        }))
        return a
    } else {
        let { data: { results } } = await axios.get(`${url}`, { headers })
        results = results.map((x) => {
            if (x.first_air_date) {
                return {
                    poster_path: x.poster_path,
                    id: x['id'],
                    type: 'tv',
                    category_name: heading,
                    url: url,
                    title: x['original_name']
                };
            } else {
                return {
                    poster_path: x.poster_path,
                    id: x['id'],
                    type: 'movie',
                    category_name: heading,
                    url: url,
                    title: x['title']
                };
            }            
        })
        return results
    }
};

export const GetListAsync = async (url) => {
    return axios.get(`${url}`, { headers })
}

export const ListResultHandler = (results, heading, url) => {
    results = results.map((x) => {
        if (x.first_air_date) {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'tv',
                category_name: heading,
                url: url,
                title: x['original_name']
            };
        } else {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'movie',
                category_name: heading,
                url: url,
                title: x['title']
            };
        }
    });
    return results
}

export const GetDetailsFromStreamDip = async (id, type, name, seasonNumber, countryCode) => {
    
        let url = `/items/${type}/${id}/${name}`
        if(seasonNumber) {
            url+=`/${seasonNumber}`
        }
        if (!countryCode) { countryCode = 'US'}

        url+=`?countryCode=${countryCode}`

        let { data } = await axios.get(`${SD_API}${url}`);
        return data;
    
};

export const GetDetails = async (id, type, name, seasonNumber) => {
    if (type === 'movie') {
        try {
            let { data } = await axios.get(`https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=en-US`, { headers })
            let rating = await axios.get(`https://www.omdbapi.com/?apikey=23120557&t=${name}`, { headers })
            let { data: { results } } = await axios.get(`https://api.themoviedb.org/3/movie/${id}/watch/providers?api_key=${API_KEY}`, { headers })
            data = {
                backdrop_path: data['backdrop_path'],
                genres: data['genres'],
                id: data['id'],
                original_title: data['original_title'],
                overview: data['overview'],
                poster_path: data['poster_path'],
                release_date: data['release_date'],
                runtime: data['runtime'],
                title: data['title'],
                provider_path: results,
                type: 'movie',
                imdb: rating?.data?.Ratings?.filter((x) => {
                    return x.Source === 'Internet Movie Database'
                }),
                tomatometer: rating?.data?.Ratings?.filter((x) => {
                    return x.Source === 'Rotten Tomatoes'
                })
            }
            return data
        } catch(e) {
            console.log('Ocorreu erro')
        }
    } else {
        let url = `https://api.themoviedb.org/3/tv/${id}?api_key=${API_KEY}&language=en-US`        
        if(seasonNumber) {
            url+=`&append_to_response=season/${seasonNumber}`
        }

        let { data } = await axios.get(url, { headers })
        let { data: { results } } = await axios.get(`https://api.themoviedb.org/3/tv/${id}/watch/providers?api_key=${API_KEY}`, { headers })
        let rating = await axios.get(`https://www.omdbapi.com/?apikey=23120557&t=${name}`, { headers })
        data = {
            backdrop_path: data['backdrop_path'],
            genres: data['genres'],
            id: data['id'],
            original_title: data['original_name'],
            overview: data['overview'],
            poster_path: data['poster_path'],
            release_date: data['last_air_date'],
            runtime: data['runtime'],
            title: data['title'] || data['name'],
            provider_path: results,
            type: 'tv',
            imdb: rating?.data?.Ratings?.filter((x) => {
                return x.Source === 'Internet Movie Database'
            }),
            tomatometer: rating?.data?.Ratings?.filter((x) => {
                return x.Source === 'Rotten Tomatoes'
            }),
            seasons: data.seasons,
            season: data[`season/${seasonNumber}`]
        }
        
        return data
    }
};

export const ProviderPathHandler = (provider_path, userCountry) => {
    let known = new Set()

    let buy_rent_provider = provider_path[userCountry]?.buy

    if (buy_rent_provider) {
        buy_rent_provider = buy_rent_provider.filter((x) => !known.has(x.provider_id) && known.add(x.provider_id))
    }

    let watch_providers = provider_path?.[userCountry]?.flatrate || []
    watch_providers = provider_path[userCountry]?.ads ? watch_providers.concat(provider_path[userCountry]?.ads) : watch_providers

    return {
        buy_rent_provider: buy_rent_provider,
        watch_providers: watch_providers
    }
}

export const DetailResultHandler = (detail, userCountry) => {
    let known = new Set()

    let buy_rent_provider = detail?.provider_path[userCountry]?.buy

    if (buy_rent_provider) {
        buy_rent_provider = buy_rent_provider.filter((x) => !known.has(x.provider_id) && known.add(x.provider_id))
    }
    detail = {
        backdrop_path: detail['backdrop_path'],
        genres: detail['genres'],
        id: detail['id'],
        original_title: detail['original_title'],
        overview: detail['overview'],
        poster_path: detail['poster_path'],
        release_date: detail['release_date'],
        runtime: detail['runtime'],
        title: detail['title'],
        watch: detail?.provider_path[userCountry]?.flatrate !== undefined ? detail?.provider_path[userCountry]?.flatrate : '',
        //watch: undefined,
        type: detail['type'],
        buy_rent: buy_rent_provider,
        imdb: detail['imdb'],
        tomatometer: detail['tomatometer'],
        seasons: detail.seasons,
    }

    return detail
}

export const GetCast = async (id, type) => {
    if (type === 'movie') {
        const { data: { cast } } = await axios.get(`https://api.themoviedb.org/3/movie/${id}/credits?api_key=${API_KEY}&language=en-US`, { headers })
        return cast
    } else {
        const { data: { cast } } = await axios.get(`https://api.themoviedb.org/3/tv/${id}/credits?api_key=${API_KEY}&language=en-US`, { headers })
        return cast
    }
};

export const SearchMulti = async (query) => {

    let multi = await axios.get(`${BASE_URL}3/search/multi?api_key=${API_KEY}&language=en-US&query=${query}&page=1&include_adult=false`, { headers })
    let result = multi.data.results.map((x) => {
        if(x.media_type == 'movie') {            
            return {
                poster_path: x?.poster_path,
                id: x.id,
                type: x.media_type,
                title: x['title'] || x['original_title'],
                popularity: x['popularity'],
                year: x?.release_date?.substring(0,4)
            }; 
        }
        
        if(x.media_type == 'tv') {
            return {
                poster_path: x?.poster_path,
                id: x.id,
                type: 'serie',
                title: x['name'] || x['original_name'],
                popularity: x['popularity'],
                year: x?.release_date?.substring(0,4) || x?.first_air_date?.substring(0,4)
            }; 
        }     
        return null
    }).filter(elem => elem!==null);;
    
    const searchResults = [...result ]
    return searchResults
};

export const Search = async (query) => {

    let movieSearchResult = await axios.get(`${BASE_URL}3/search/movie?api_key=${API_KEY}&language=en-US&query=${query}&page=1&include_adult=false`, { headers })
    movieSearchResult = movieSearchResult.data.results.map((x) => {
        if (x.first_air_date) {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'tv',
                title: x['original_name'] || x['name'] || x['title'],
                popularity: x['popularity']
            };
        } else {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'movie',
                title: x['title'] || x['name'] || x['original_name'],
                popularity: x['popularity']
            };
        }
    });
    let tvSearchResult = await axios.get(`${BASE_URL}3/search/tv?api_key=${API_KEY}&language=en-US&query=${query}&page=1&include_adult=false`, { headers })
    tvSearchResult = tvSearchResult.data.results.map((x) => {
        if (x.first_air_date) {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'tv',
                title: x['original_name'] || x['name'] || x['title'],
                popularity: x['popularity']
            };
        } else {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'movie',
                title: x['title'] || x['name'] || x['original_name'],
                popularity: x['popularity']
            };
        }
    });

    // let personSearchResult = await axios.get(`${BASE_URL}3/search/person?api_key=${API_KEY}&language=en-US&query=${query}&page=1&include_adult=false`)
    // personSearchResult = personSearchResult.data.results.map((x) => (
    //     x.known_for.map((y) => {
    //         return {
    //             poster_path: y.poster_path,
    //             id: y.id,
    //             type: y.media_type,
    //             title: y['title'] || y['original_title'] || y['original_name'],
    //             popularity: y['popularity']
    //         }
    //     })
    // ))
    // let personSearchResultArray = []
    // personSearchResult.map((x) => x && x.map((y) => personSearchResultArray.push(y)))
    // const searchResults = [...movieSearchResult, ...tvSearchResult, ...personSearchResultArray]
    const searchResults = [...movieSearchResult, ...tvSearchResult]
    return searchResults
};

export const GetCategoriesList = async (id, watchProviderName = '', WatchProviderId, selectedType, userCountry) => {
    let categoryArray = {
        '28': 'Action',
        '12': 'Adventure',
        '16': 'Animation',
        '35': 'Comedy',
        '80': 'Crime',
        '99': 'Documentary',
        '18': 'Drama',
        '10751': 'Family',
        '14': 'Fantasy',
        '36': 'History',
        '27': 'Horror',
        '10402': 'Music',
        '9648': 'Mystery',
        '10749': 'Romance',
        '878': 'Science Fiction',
        '10770': 'TV Movie',
        '53': 'Thriller',
        '10752': 'War',
        '37': 'Western',
        '10759': 'Action & Adventure',
        '10762': 'Kids',
        '10763': 'News',
        '10764': 'Reality',
        '10765': 'Sci-Fi & Fantasy',
        '10766': 'Soap',
        '10767': 'Talk',
        '10768': 'War & Politics',
    }
    const name = id === 'trending'
    ? `${selectedType === 'movie' ? 'Trending Movies' : 'Trending Series'}${watchProviderName ? ` on ${watchProviderName}` : ''}`
    : `${categoryArray[id] || ''} ${selectedType === 'movie' ? 'Movies' : 'Series'}${watchProviderName ? ` - ${watchProviderName}` : ''}`
    
    console.log(`Country on search: ${userCountry}`)
    let url = ''
    if (id === 'trending') {
        url = `${BASE_URL}3/discover/${selectedType}?api_key=${API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_keywords=trending&with_watch_providers=${WatchProviderId}&watch_region=${userCountry}&with_watch_monetization_types=flatrate`
    } else {
        url = `${BASE_URL}3/discover/${selectedType}?api_key=${API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=${id}&with_watch_providers=${WatchProviderId}&watch_region=${userCountry}&with_watch_monetization_types=flatrate`
    }
    
    let { data: { results } } = await axios.get(`${url}`, { headers })
    
    if(WatchProviderId ===350) { // If AppleTV+ search on AppleTV too
        let urlApple = `${BASE_URL}3/discover/${selectedType}?api_key=${API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=${id}&with_watch_providers=2&watch_region=${userCountry}&with_watch_monetization_types=flatrate`
        let resp = await axios.get(`${urlApple}`, { headers })
        let resultsApple = resp.data.results        
        results = results.concat(resultsApple)        
    }

    
    if (results === '') {
        results.push({
            type: selectedType,
            category_name: `${categoryArray[id] !== undefined ? categoryArray[id] : ''} ${selectedType === 'movie' ? 'Movies' : 'Series'} ${watchProviderName.length > 0 ? `- ${watchProviderName}` : ``}`
        })
    } else {
        results = results.map((x) => {
            return {
                title: x['original_title'] || x['name'],
                poster_path: x.poster_path,
                id: x['id'],
                type: selectedType,
                category_id: id,
                watchProvider: WatchProviderId,
                category_name: name,
                // category_name: `${id === 'trending' ? selectedType == 'movie' ? `Trending Movies ${watchProviderName ? `on ${watchProviderName}` : ''}` : `Trending Series ${watchProviderName ? `on ${watchProviderName}` : ''}` : `${categoryArray[id] !== undefined ? categoryArray[id] : ''} ${selectedType == 'movie' ? 'Movies' : 'Series'} ${watchProviderName.length > 0 ? `- ${watchProviderName}` : ``}`} `,
                url: `${BASE_URL}3/discover/${selectedType}?api_key=${API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_watch_providers=${WatchProviderId}&watch_region=${userCountry}&with_genres=${id}&with_watch_monetization_types=flatrate`
            };
        });
    }
    return results
};

export const Similar = async (id, type) => {
    if (type === 'movie') {
        let { data: { results } } = await axios.get(`https://api.themoviedb.org/3/movie/${id}/recommendations?api_key=${API_KEY}&language=en-US&page=1`, { headers })

        results = results.map((x) => {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: type,
                title: x['title']
            }
        })
        return results
    } else {
        let { data: { results } } = await axios.get(`https://api.themoviedb.org/3/tv/${id}/recommendations?api_key=${API_KEY}&language=en-US&page=1`, { headers })
        results = results.map((x) => {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: type,
                title: x['original_name']
            }
        })
        return results
    }
};

export const Login = async (loginCredentials) => {
    let request = {};
    request["email"] = `${loginCredentials && loginCredentials.email}`;
    request["password"] = `${loginCredentials && loginCredentials.password}`;
    let { data } = await axios.post(`${SD_API}/user/sign-in`, request, { headers })
    return data
};

const renewToken = async (refreshToken) => {
    let request = {};
    request["refreshToken"] = refreshToken;
    let { data } = await axios.post(`${SD_API}/user/refresh`, request, { headers })
    return data
};

export const signUp = async (loginCredentials) => {
    let request = {};
    request["email"] = `${loginCredentials && loginCredentials.email}`;
    request["password"] = `${loginCredentials && loginCredentials.password}`;
    request["first_name"] = `${loginCredentials && loginCredentials.first_name}`;
    request["last_name"] = `${loginCredentials && loginCredentials.last_name}`;
    let { data } = await axios.post(`${SD_API}/user/sign-up`, request, { headers })
    return data
};

export const emailOtp = async (email, otp) => {
    let request = {};
    request["otp"] = `${otp && otp}`;
    request["email"] = `${email && email}`;
    let { data } = await axios.post(`/user/verify-otp`, request, { headers })
    return data
};

export const LoadMoreApi = async (url, pageIncrement) => {
    let updatedUrl = url
    updatedUrl = updatedUrl.replace('page=1', `page=${pageIncrement}`)
    let { data } = await axios.get(`${updatedUrl}`, { headers })
    
    let results = data.results
    let total_pages = data.total_pages

    if(updatedUrl.includes('with_watch_providers=350')) {//Search on AppleTV content too
        let appleTVUrl = updatedUrl.replace('with_watch_providers=350', `with_watch_providers=2`)
        let resp = await axios.get(`${appleTVUrl}`, { headers })
        results = results.concat(resp.data.results)
        if(resp.data.total_pages>total_pages) {
            total_pages = resp.data.total_pages
        }
    }

    results = results.map((x) => {
        if (x.first_air_date) {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'tv',
                title: x['original_name'],
                total_pages: total_pages
            };
        } else {
            return {
                poster_path: x.poster_path,
                id: x['id'],
                type: 'movie',
                title: x['title'],
                total_pages: total_pages
            };
        }
    });
    return results
};

export const addWishListApi = async (id, poster_path, type, email, title) => {
    let request = {};
    request["id"] = `${id}`;
    request["poster_path"] = `${poster_path}`;
    request["type"] = `${type}`;
    request["email"] = `${email}`;
    request["title"] = `${title}`;
    const newHeaders = await getAuthHeader(headers);
    console.log(newHeaders)
    let { data } = await axios.post(`${SD_API}/user/add-watchlist`, request, { headers: newHeaders })
    return data
};

export const getWishList = async (email) => {
    let request = {};
    request["email"] = `${email}`;    
    const newHeaders = await getAuthHeader(headers);    
    let { data } = await axios.post(`${SD_API}/user/get-watchlist`, request, { headers: newHeaders })
    return data
};

export const deleteWishList = async (id, type, email) => {
    let request = {};
    request["email"] = `${email}`;
    request["id"] = `${id}`;
    request["type"] = `${type}`;
    const newHeaders = await getAuthHeader(headers);
    let { data } = await axios.post(`${SD_API}/user/delete-watchlist`, request, { headers: newHeaders })
    return data
};

export const emailVerify = async (email) => {
    let request = {};
    request["email"] = `${email}`;
    let { data } = await axios.post(`/user/verify-email`, request, { headers })
    return data
};

export const resetPasswordFunction = async (email, password) => {
    let request = {};
    request["email"] = `${email}`;
    request["password"] = `${password}`;
    let { data } = await axios.put(`${SD_API}/user/forgot-password`, request, { headers })
    return data
};

export const resetPasswordFunctionWithCode = async (code, password) => {
    let request = {};
    request["code"] = `${code}`;
    request["password"] = `${password}`;
    let { data } = await axios.put(`${SD_API}/user/forgot-password-code`, request, { headers })
    return data
};


export const updateProfile = async (email, firstName, lastName) => {
    let request = {};
    request["email"] = `${email}`;
    request["first_name"] = `${firstName}`;
    request["last_name"] = `${lastName}`;
    let { data } = await axios.put(`${SD_API}/user/update-profile`, request, { headers })
    return data
};

export const Trailer = async (id, type) => {
    let { data: { results } } = await axios.get(`${BASE_URL}3/${type}/${id}/videos?api_key=${API_KEY}&language=en-US`, { headers })
    results = results.filter((x) => { return x.type === 'Trailer' })
    return results[0]
}

export const TrailerAsync = (id, type) => {
    return axios.get(`${BASE_URL}3/${type}/${id}/videos?api_key=${API_KEY}&language=en-US`, { headers })    
}

export const adminSignIn = async (loginCredentials) => {
    let request = {};
    request["email"] = `${loginCredentials && loginCredentials.email}`;
    request["password"] = `${loginCredentials && loginCredentials.password}`;
    let { data } = await axios.post(`/admin/admin-signin`, request, { headers })
    return data
};
