import React, { useEffect, useState, useRef } from 'react'
import './details.css';
import { useLocation, Link, useParams } from 'react-router-dom'
import { GetDetails, GetDetailsFromStreamDip, DetailResultHandler, getWishList, addWishListApi, deleteWishList, Trailer } from '../APIS/apis'
import Slider from "react-slick";
import moment from 'moment';
import { ThreeCircles } from 'react-loader-spinner'
import { BsFillPlayFill } from 'react-icons/bs';
import WatchProviderLink from './components/WatchProviderLink';
import Accordion from 'react-bootstrap/Accordion';

function SeasonDetails({ userDetails, userCountry }) {
    // console.log(userCountry)
    const backDropPath = 'https://image.tmdb.org/t/p/w500'
    const location = useLocation()
    const params = useParams()
    // const navigate = useNavigate();
    let { id } = location.state || ''
    let { type } = location.state || ''
    let { title } = location.state || ''
    const [details, setDetails] = useState({})
    const [wishListArray, setWishListArray] = useState(false)
    const [trailerId, setTrailerId] = useState('')
    const [trailerActive, setTrailerActive] = useState(false)
    const [loader, setLoader] = useState(true)

    const trailerModal = useRef()

    const addToWishList = async (id, poster_path, type, title) => {
        setLoader(true)
        if (userDetails) {
            await addWishListApi(id, poster_path, type, userDetails.email, title)
            setWishListArray(true)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    const deleteFromWishList = async (id) => {
        setLoader(true)
        await deleteWishList(id, userDetails.email)
        setWishListArray(false)
        setLoader(false)
    }

    useEffect(() => {
        (async () => {
            // console.log("hello", id, type, details)
            // if (id && type) {
            //     console.log(id, type)
            //     const trailers = await Trailer(id, type)
            //     setTrailerId(trailers?.key)
            // }
            // if (userDetails && details) {
            //     const wishListArray = await getWishList(userDetails.email)
            //     for (var i = 0; i < wishListArray.length; i++) {
            //         if (wishListArray[i].id == details.id) {
            //             setWishListArray(true)
            //             break;
            //         } else {
            //             setWishListArray(false)
            //         }
            //     }
            // }
        })
            ();
        window.scrollTo(0, 0)
    }, [userDetails, details, id, type])

    useEffect(() => {
        (async () => {
            console.log(`${params.title} (ID: ${params.id}) Season: ${params.seasonNumber}`)
            let detail = await GetDetailsFromStreamDip(params.id, params.type, params.title, params.seasonNumber)
            //detail = DetailResultHandler(detail, userCountry)

            setDetails(detail)                        
            setLoader(false)
        })
            ();
    }, [params, userCountry])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!trailerModal?.current?.contains(event.target)) {
                setTrailerActive(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trailerModal]);

    useEffect(() => {
        (async () => {
            if (details && details?.id && details?.type) {
                document.title = `StreamDip | ${details.title || ''} | ${details.overview || ''}`;
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', details.overview);
                }
                const trailers = await Trailer(details?.id, details?.type)
                setTrailerId(trailers?.key)                
            }
        })
            ();
    }, [title, details])




    return (
        <>
            {!loader &&
                <>
                    <section className="detail_section movie_series_detail">
                        <div className="container-fluid">
                            <div>
                                <div className="background_path_home" style={{ backgroundImage: `url('${backDropPath}${details.backdrop_path}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                    <div className="row pt-5 main_detail_row" style={{ backgroundImage: 'linear-gradient(to right, rgba(22, 22, 26, 1) 150px, rgba(22, 22, 26, 0.84) 100%)' }}>
                                        <div className='col-md-12 detail_upper_btn_section'>
                                            {/* <Link to={'/'} className='btn btn_back'><i className="fa fa-angle-left" aria-hidden="true"></i> Go Back</Link> */}
                                            {/* <button onClick={() => { navigate(-1); window.scrollTo(0, 0) }} className='btn btn_back btn_back_detail'><i className="fa fa-angle-left" aria-hidden="true"></i> Go Back</button> */}
                                            {wishListArray ?
                                                <button className='btn btn_wishlist float-end bg-white text-black position-relative' onClick={() => deleteFromWishList(details.id)}>
                                                    {!loader ? 'Remove from WatchList' :
                                                        <ThreeCircles
                                                            height="100"
                                                            width="100"
                                                            color="#ffe01b"
                                                            wrapperStyle={{ margin: 'auto' }}
                                                            wrapperClass="loader"
                                                            visible={loader}
                                                            ariaLabel="three-circles-rotating"
                                                            outerCircleColor=""
                                                            innerCircleColor=""
                                                            middleCircleColor=""
                                                        />}
                                                </button>
                                                :
                                                <>
                                                    {userDetails ?
                                                        <button className='btn btn_wishlist btn_wishlist_add float-end position-relative' onClick={() => addToWishList(details.id, details.poster_path, details.type, details.title)} >
                                                            {!loader ? 'Add to WatchList' :
                                                                <ThreeCircles
                                                                    height="100"
                                                                    width="100"
                                                                    color="#ffe01b"
                                                                    wrapperStyle={{ margin: 'auto' }}
                                                                    wrapperClass="loader"
                                                                    visible={loader}
                                                                    ariaLabel="three-circles-rotating"
                                                                    outerCircleColor=""
                                                                    innerCircleColor=""
                                                                    middleCircleColor=""
                                                                />
                                                            }
                                                        </button>
                                                        :
                                                        <button className='btn btn_wishlist float-end' data-bs-toggle="modal" data-bs-target="#signin" >Add to WatchList</button>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-8 col-md-12 mx-auto">
                                            <div className="row">
                                                <div className="col-md-4 col-5">
                                                    <div className="detail_poster">
                                                        <img src={`${backDropPath}${details.poster_path}`} width="100%" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-7 movie_detail_padding">
                                                    <div className="movie_details">
                                                        <div className='row'>
                                                            <div className='col-lg-8 col-md-12'>
                                                                <h2 className="title mb-2 text-white">{details.original_title}</h2>
                                                                <p className="release_date mb-0">{moment(details.release_date).format('MMMM Do YYYY')}{details.runtime && <> <span className="mx-1">.</span> <>{details.runtime} Minutes</></>}</p>
                                                                <p className="genre">
                                                                    {details.genres?.map((x, i) => (
                                                                        <small key={i}>
                                                                            {x.name}<span className="mx-1">.</span>
                                                                        </small>
                                                                    ))}
                                                                </p>
                                                                {trailerId &&
                                                                    <span className='play_trailer' aria-hidden="true" data-bs-toggle="modal" data-bs-target="#trailerModal" onClick={() => setTrailerActive(true)}><BsFillPlayFill style={{ fontSize: '30px', marginLeft: '-7px', marginTop: '-3px' }} /> Play Trailer</span>
                                                                }
                                                                <div className='movie_details_mob'>
                                                                    {details?.watch && <h6 className='text-white watch_here_label'>Stream or sign up here</h6>}
                                                                    <div className='watch_provider_slider_mob'>
                                                                        {details.watch && details?.watch?.map((x) => (
                                                                            <>
                                                                                {x?.provider_name !== 'HBO Max' &&
                                                                                    <>                                                                                        
                                                                                        <WatchProviderLink providerName={x?.provider_name} title={details.original_title} logoPath={x.logo_path} />
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                    {details?.buy_rent && <h6 className='text-white watch_here_label mt-3'>Buy or Rent here</h6>}
                                                                    <div className='buy_rent_slider_mob'>
                                                                        {details?.buy_rent?.map((x) => (
                                                                            <>
                                                                                {x?.provider_name !== 'HBO Max' &&
                                                                                    <>
                                                                                        <WatchProviderLink providerName={x?.provider_name} title={details.original_title} logoPath={x.logo_path} />
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>                                                                   
                                                                    {details?.watch?.length > 0 || details?.buy_rent_provider !== undefined ? '' :
                                                                        <div className='col-md-6'>
                                                                            <h6 className='text-white watch_here_label py-3'>Not available to stream in your location</h6>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='movie_deatils movie_details_desktop'>
                                                            <h5 className="overview_label mt-4 text-white">Overview
                                                                <span className='mx-3 rating-icon'> {details?.imdb?.length > 0 ? <span><img src="/imdb.png" width='30px' alt="" /> {details.imdb[0]?.Value}</span> : ''} </span> <span className='rating-icon'> {details?.tomatometer?.length > 0 ? <span><img src="/rt.png" width='25px' alt="" /> {details.tomatometer[0]?.Value} </span> : ''} </span>
                                                            </h5>
                                                            <p className="overview">{details.overview}</p>
                                                            <div className='row'>
                                                                {details.watch &&
                                                                    <div className='col-md-6'>
                                                                        {details?.watch && <h6 className='text-white watch_here_label'>Stream or sign up here</h6>}
                                                                        <div className='watch_provider_slider'>
                                                                            {details.watch && details?.watch?.map((x) => (
                                                                                <>
                                                                                    {x?.provider_name !== 'HBO Max' &&
                                                                                        <>
                                                                                            <WatchProviderLink providerName={x?.provider_name} title={details.original_title} logoPath={x.logo_path} />
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {details?.buy_rent &&
                                                                    <div className='col-md-6'>
                                                                        {details?.buy_rent && <h6 className='text-white watch_here_label'>Buy or Rent here</h6>}
                                                                        <div className='watch_provider_slider mb-3'>
                                                                            {details?.buy_rent?.map((x) => (
                                                                                <>
                                                                                    {x?.provider_name !== 'HBO Max' &&
                                                                                        <>
                                                                                            <WatchProviderLink providerName={x?.provider_name} title={details.original_title} logoPath={x.logo_path} />
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                }                                                               
                                                                {details?.watch?.length > 0 || details?.buy_rent_provider?.lenght>0 ? '' :
                                                                
                                                                    <div className='col-md-6'>
                                                                        <h6 className='text-white watch_here_label'>Not available to stream in your location</h6>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className='col-md-12'>
                        <div className='movie_deatils movie_details_mob movie_details_mobile_two'>
                            <h5 className="overview_label mt-4 text-white">Overview
                                <span className='mx-3 rating-icon'>{details?.imdb?.length > 0 ? <span><img src="/imdb.png" width='30px' alt="" /> {details.imdb[0]?.Value}</span> : ''} </span> <span className='rating-icon'> {details?.tomatometer?.length > 0 ? <span><img src="/rt.png" width='25px' alt="" /> {details.tomatometer[0]?.Value}</span> : ''} </span>
                            </h5>
                            <p className="overview">{details.overview}</p>
                        </div>
                    </div>
                    
                    <section className='similar_movies'>
                        <div className='container-fluid'>
                            <div className='row similar_movies_row'>
                                <div className='col-md-12'>
                                    <h2 className='text-white'>Season {params.seasonNumber}</h2>
                                    {/* <div className='text-white'>{details?.season?.air_date}</div> */}
                                    <Accordion defaultActiveKey="0">
                                    {details?.season?.episodes?.map((ep, i) => {
                                        return  <Accordion.Item eventKey={i}>
                                            <Accordion.Header className='ep-accordion'>Episode {ep.episode_number} - {ep.name}</Accordion.Header>
                                            <Accordion.Body>
                                                {ep.overview}
                                            </Accordion.Body>                                            
                                        </Accordion.Item>
                                    })}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="modal fade" id="trailerModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog bg-black">
                            <div className="modal-content position-relative h-100">
                                <div className="modal-header bg-black border-0 ">
                                    <h5 className="modal-title text-white" id="staticBackdropLabel">Play Trailer</h5>
                                    <button type="button" className='btn' data-bs-dismiss="modal" aria-label="Close" onClick={() => setTrailerActive(false)}><i style={{ fontSize: "24px" }} className="fa fa-times text-white" aria-hidden="true"></i></button>
                                </div>
                                {trailerActive ?
                                    <iframe
                                        // src={`https://www.youtube.com/embed/${trailerId}?autoplay=1`}
                                        src={`https://www.youtube.com/embed/${trailerId}?rel=0&amp;autoplay=1`}
                                        frameborder='0'
                                        allow="autoplay"
                                        allowfullscreen
                                        title='video'
                                        className='responsive-iframe'
                                    />
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

            <ThreeCircles
                height="100"
                width="100"
                color="#ffe01b"
                wrapperStyle={{ margin: 'auto' }}
                wrapperClass="loader"
                visible={loader}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </>
    )
}

export default SeasonDetails