import React, { useState, useRef, useEffect } from 'react'
import './navbar.css';
import { Link } from 'react-router-dom';
import { CiFilter } from 'react-icons/ci';
import { BiUserCircle } from 'react-icons/bi';
import { MdModeEditOutline } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { HiOutlineLogout } from 'react-icons/hi';

import { deleteCookie } from '../../Services/TokenFunction';
import ReactFlagsSelect from "react-flags-select";
import { SearchMulti } from '../../APIS/apis';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function NavBar(
    {
        clearFiltersAndSearch,
        setSideBarActive,
        country,
        selectCountry,
        isAuthenticated,
        setIsAuthenticated,
        GetSearchQuery,
        search,
        setSearch,
        inputRef,
        userDetails
    }) {

    const backDropPath = 'https://image.tmdb.org/t/p/w200'

    const [results, setResults] = useState([])
    const [dropdown, setDropdown] = useState(false);
    const [isInputSearchFocused, setIsInputSearchFocused] = useState(false);
    const toggleOpen = () => setDropdown(!dropdown);
    const searchWrapperRef = useRef(null);
    useOutsideAlerter(searchWrapperRef);

    const navigate = useNavigate();

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                setIsInputSearchFocused(false)
            } else {
                setIsInputSearchFocused(true)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        }, [ref]);
    }

    const handleSearchChange = async (event) => {
        setSearch(event.target.value);
        const value = event.target.value;
        //console.log(value)
        let result = await SearchMulti(value)
        // console.log(result)
        
        if (value.length > 0) {            
            setResults(result.slice(0,4));
        } else {
            setResults([]);
            navigate('/')
        }
    };

    const handleClick = async (result) => {        
        let type = result.type === 'serie' ? 'series' : 'movies'
        let url = `/find-${type}/${result.id}/${result.title}`
        setIsInputSearchFocused(false)
        navigate(url)

    };

    return (<nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to={'/'} className="navbar-brand" onClick={() => { clearFiltersAndSearch() }}>
                        <img src="/logo.png" style={{ width: 202 }} alt='Logo' />
                    </Link>
                    <div className='sign_in_mobile'>
                        <CiFilter style={{ fontSize: '20px', marginTop: '10px', marginRight: '15px' }} onClick={() => { setSideBarActive(true); }} />
                        {country &&
                            <span className='mobile_flag_span position-relative'>
                                {/* <span className='country_name'>
                                    {country}
                                </span> */}
                                <ReactFlagsSelect
                                    selected={country}
                                    onSelect={(code) => selectCountry(code)}
                                    countries={["AU", 'CA', "GB", "US"]}
                                />
                                {/* <CountryDropdown id="country_dropdown" className='mobile_flag' preferredCountries={['gb', 'us']} value={country} handleChange={e => setCountry(e.target.value)}></CountryDropdown> */}
                            </span>
                        }
                        {isAuthenticated ?
                            <div className="btn-group mobile_user_dropdown">
                                <button className="btn btn-secondary user_dropdown_button dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false" onClick={toggleOpen}>
                                    <span className='profile_image_mobile'>
                                        <BiUserCircle style={{ fontSize: '30px', marginTop: '5px' }} />
                                    </span>
                                </button>
                                <ul className={`dropdown-menu user_dropdown ${dropdown ? 'show' : ''}`} aria-labelledby="one">
                                    <li className='p-2' data-bs-toggle="modal" data-bs-target="#profile" onClick={toggleOpen}><MdModeEditOutline style={{ fontSize: '20px' }} /> Edit Profile</li>
                                    <li className='p-2' onClick={toggleOpen}><Link to={'/watchlist'} style={{ textDecoration: 'none', color: '#000' }}><MdFavorite style={{ fontSize: '20px' }} /> My WatchList</Link></li>
                                    <li onClick={() => { setIsAuthenticated(false); setDropdown(false); deleteCookie("token"); toggleOpen() }} className='p-2'><HiOutlineLogout style={{ fontSize: '20px' }} /> Log out</li>
                                </ul>
                            </div>
                            :
                            <button className='btn' data-bs-toggle="modal" data-bs-target="#signin">
                                <BiUserCircle style={{ fontSize: '30px', marginTop: '-2px' }} />
                            </button>
                            // <button className='btn btn_sign_in' data-bs-toggle="modal" data-bs-target="#signin">Sign in</button>
                        }
                    </div>
                    {/* <CountryDropdown  id="country_dropdown" className='YOUR_CSS_CLASS' preferredCountries={['gb', 'us']}  value='Pakistan' handleChange={e => console.log(e.target.value)}></CountryDropdown> */}
                    <form className="d-flex mx-auto search_input_form" onSubmit={e => { e.preventDefault(); GetSearchQuery(search) }}>
                        <div className="input-group mb-3" ref={searchWrapperRef}>
                            <input className="form-control nav_search" ref={inputRef} value={search} type="search" onChange={handleSearchChange}
                                placeholder="Search for Movies or Series..."
                                aria-label="Search"
                                onFocus={() => setIsInputSearchFocused(true)} 
                                />
                            {/* <span className={`input-group-text`} onClick={(e) => GetSearchQuery(search)}>Search</span> */}
                            {search!=''&& isInputSearchFocused && (
                                <ul className="result-list" onBlur={() => {
                                    console.log('Cliquei foraa')
                                    setIsInputSearchFocused(false)}}>
                                    {results.map((result, index) => (
                                        <li key={index} onClick={() => handleClick(result)}>
                                            <Row>
                                                <Col xs={3}>
                                                    <img src={`${backDropPath}${result.poster_path}`} className='img-fluid' alt=""/>
                                                </Col>
                                                <Col xs={7}>
                                                    <h5>{result.title}</h5>
                                                    {result.type}, {result.year}
                                                </Col>
                                            </Row>
                                            
                                        </li>
                                    ))}
                                    <li key={4} onClick={() => {                                       
                                        //navigate(`/search/${search.replace(/\s+/g, '-')}`)}
                                        }}>
                                        {/* <Link to={`/search/${search.replace(/\s+/g, '-')}`}> */}
                                            {/* <Row className="justify-content-center" > */}
                                            <div className='text-center'>
                                                <Button className="btn btn-clock rounded-pill" variant="dark"  onClick={() => 
                                                    {
                                                        console.log('Cliquei search')
                                                        navigate(`/search/${search.replace(/\s+/g, '-')}`);
                                                        setIsInputSearchFocused(false)
                                                        
                                                    }}>
                                                    Search all results
                                                </Button>
                                            </div>
                                            {/* <span className={`input-group-text justify-content-center`} >Search all results</span> */}
                                                    
                                                
                                            {/* </Row> */}
                                        {/* </Link> */}
                                    </li>
                                </ul>
                            )}
                        </div>                        
                    </form>
                    
                    {isAuthenticated ?
                        <div className="btn-group desktop_user_dropdown">
                            <button className="btn btn-secondary user_dropdown_button dropdown-toggle" type="button" id="two" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" onClick={toggleOpen}>
                                <BiUserCircle style={{ fontSize: '30px' }} />
                                {userDetails.first_name}
                            </button>
                            <ul className={`dropdown-menu user_dropdown ${dropdown ? 'show' : ''}`} aria-labelledby="two">
                                <li className='p-2' data-bs-toggle="modal" data-bs-target="#profile" onClick={toggleOpen}><MdModeEditOutline style={{ fontSize: '20px' }} /> Edit Profile</li>
                                <li className='p-2' onClick={toggleOpen}><Link to={'/watchlist'} style={{ textDecoration: 'none', color: '#000' }}><MdFavorite style={{ fontSize: '20px' }} /> My WatchList</Link></li>
                                <li onClick={() => { setIsAuthenticated(false); setDropdown(false); deleteCookie("token"); toggleOpen() }} className='p-2'><HiOutlineLogout style={{ fontSize: '20px' }} /> Log out</li>
                            </ul>
                        </div>
                        :
                        <div className='sign_in'>
                            {/* <button className='btn btn_sign_in' data-bs-toggle="modal" data-bs-target="#signin">Sign in</button> */}
                            <button className='btn' data-bs-toggle="modal" data-bs-target="#signin">
                                <BiUserCircle style={{ fontSize: '30px' }} />
                            </button>
                        </div>
                    }
                    {
                        // console.log(`Country: ${country}`)
                    }
                    {
                    country &&
                        <span className='desktop_flag_dropdown_span position-relative'>
                            <ReactFlagsSelect
                                selected={country}
                                countries={["AU", 'CA', "GB", "US"]}
                                onSelect={(code) => selectCountry(code)}
                            />
                        </span>
                    }
                </div>
            </nav>)
}